import { SvgIcon } from '@mui/material'

export const MerchantIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M4.99867 6.99939C4.99867 7.82816 4.32682 8.50001 3.49805 8.50001"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00003 6.99939C8.00003 7.82816 7.32818 8.50001 6.4994 8.50001C5.67063 8.50001 4.99878 7.82816 4.99878 6.99939"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0013 6.99939C11.0013 7.82816 10.3294 8.50001 9.50063 8.50001C8.67185 8.50001 8 7.82816 8 6.99939"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5018 8.50001C11.6731 8.50001 11.0012 7.82816 11.0012 6.99939"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3354 8.24723V14.5025"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6695 14.5025H1.33057"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66453 8.24589V14.5025"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="9.00049"
          y="10.5008"
          width="2.66778"
          height="2.00083"
          rx="0.5"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66528 14.5025V10.8343C4.66528 10.6501 4.81458 10.5008 4.99876 10.5008H6.66612C6.85029 10.5008 6.99959 10.6501 6.99959 10.8343V14.5025"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.99876 6.99939V6.49918"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99998 6.99939V6.49918"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0012 6.99939V6.49918"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9432 6.49916H2.05688"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5019 8.49998V8.49998C13.3307 8.49998 14.0026 7.82813 14.0026 6.99936V6.91466C14.0026 6.64277 13.9286 6.376 13.7885 6.143L12.3879 3.81203C12.1473 3.41107 11.7144 3.16536 11.2468 3.16443H4.75339C4.28484 3.16443 3.85065 3.41026 3.60958 3.81203L2.20899 6.143C2.0698 6.37627 1.99671 6.64302 1.99757 6.91466V6.99936C1.99757 7.82813 2.66943 8.49998 3.4982 8.49998V8.49998"
          stroke="#D64123"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
