import SvgIcon from '@mui/material/SvgIcon'
export const SearchIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66683 14.0007C11.1646 14.0007 14.0002 11.1651 14.0002 7.66732C14.0002 4.16951 11.1646 1.33398 7.66683 1.33398C4.16903 1.33398 1.3335 4.16951 1.3335 7.66732C1.3335 11.1651 4.16903 14.0007 7.66683 14.0007Z"
        stroke="#374151"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 14.6673L13.3335 13.334"
        stroke="#374151"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
