import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider } from 'react-i18next'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'

import { ChevronRight } from '@mui/icons-material'
import {
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  Toolbar,
  Typography,
} from '@mui/material'

import { ErrorHandler, SearchLocation } from '@shared/components'
import { EnforceLoginProvider } from '@shared/hooks'
import { LocationProvider, useLocations } from '@shared/hooks/useLocations'
import {
  FtpSetNavigatorMenuEvent,
  FtpSetApplicationBannerEvent,
  FtpSetPortalVersionEvent,
  NavigatorMenuItem,
  ApplicationBanner,
  PortalVersion,
  FtpSetNavigatorShowLocationEvent,
} from '@shared/types'

import { ParentMenuElement, ChildMenuElement } from '@/components'

import LocationSelector from './components/locations/locationSelector'
import i18n from './i18n'

const menuOpenWidth = 258
const menuClosedWidth = 110

const useStyles = tss
  .withName('FtpNavigator')
  .withParams<{ width: number }>()
  .create(({ theme, width }) => ({
    drawer: {
      width,
      flexShrink: 0,
    },
    drawerPaper: {
      width,
    },
    button: {
      borderRadius: '1rem',
      zIndex: 1000,
      color: 'grey',
    },
    divider: {
      width: '100%',
      height: '1px',
      background: theme.palette['neutral-200'],
      borderStyle: 'none',
      borderRadius: '100%',
      marginRight: '20px',
    },
    navigatorFooter: {
      bottom: 0,
      padding: 10,
      width: '100%',
      display: 'flex',
    },
    footerContent: {
      alignItems: 'center',
      width: '100%',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
    },
    footerItem: {
      padding: '0px 16px 0px 16px',
    },
    menuList: {
      flexGrow: 1,
      overflowY: 'auto',
      width: '100%',
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    toolbar: {
      flexGrow: 0,
      marginTop: '20px',
      justifyContent: 'center',
    },
    locationWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '0 24px',
      zIndex: 1000,
    },
  }))

export default function FtpNavigator() {
  const [open, setOpen] = useState<boolean>(true)
  const [selectedItem, setSelectedItem] = useState<string>('none')
  const [menuItems, setMenuItems] = useState<NavigatorMenuItem[]>([])
  const [menuBanner, setMenuBanner] = useState({} as ApplicationBanner)
  const [portalVersion, setPortalVersion] = useState({} as PortalVersion)
  const [showLocationBar, setShowLocationBar] = useState<boolean>()
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [isLocationSelectorModalOpen, setIsLocationSelectorModalOpen] =
    useState(false)

  const currentWidth = open ? menuOpenWidth : menuClosedWidth
  const { classes } = useStyles({ width: currentWidth })

  useEffect(() => {
    const setNewMenu = (newMenuEvent: FtpSetNavigatorMenuEvent) => {
      setMenuItems(newMenuEvent.navigatorMenu.menuItems)
    }

    const setNewShowLocationBar = (
      newShowLocation: FtpSetNavigatorShowLocationEvent
    ) => {
      setShowLocationBar(newShowLocation.showLocationBar)
    }

    window.addEventListener(
      'FTP_SET_NAVIGATOR_MENU',
      setNewMenu as EventListener
    )

    window.addEventListener(
      'FTP_NAVIGATOR_SET_SHOW_LOCATION',
      setNewShowLocationBar as EventListener
    )

    const navigatorLoadedEvent = new Event('FTP_NAVIGATOR_LOADED')
    window.dispatchEvent(navigatorLoadedEvent)

    return () => {
      window.removeEventListener(
        'FTP_SET_NAVIGATOR_MENU',
        setNewMenu as EventListener
      )
      window.removeEventListener(
        'FTP_NAVIGATOR_SET_SHOW_LOCATION',
        setNewShowLocationBar as EventListener
      )
    }
  }, [])

  useEffect(() => {
    const setNewBanner = (newBannerEvent: FtpSetApplicationBannerEvent) => {
      setMenuBanner(newBannerEvent.banner)
    }

    window.addEventListener(
      'FTP_NAVIGATOR_SET_APPLICATION_BANNER',
      setNewBanner as EventListener
    )

    const navigatorLoadedEvent = new Event('FTP_NAVIGATOR_LOADED')
    window.dispatchEvent(navigatorLoadedEvent)

    return () =>
      window.removeEventListener(
        'FTP_NAVIGATOR_SET_APPLICATION_BANNER',
        setNewBanner as EventListener
      )
  }, [])

  useEffect(() => {
    const setNewPortalVersion = (
      newPortalVersionEvent: FtpSetPortalVersionEvent
    ) => {
      setPortalVersion(newPortalVersionEvent.version)
    }

    window.addEventListener(
      'FTP_NAVIGATOR_SET_PORTAL_VERSION',
      setNewPortalVersion as EventListener
    )

    const navigatorLoadedEvent = new Event('FTP_NAVIGATOR_LOADED')
    window.dispatchEvent(navigatorLoadedEvent)

    return () =>
      window.removeEventListener(
        'FTP_NAVIGATOR_SET_PORTAL_VERSION',
        setNewPortalVersion as EventListener
      )
  }, [])

  const handleClick = (url: string, isExternal = false): void => {
    if (!url) return
    setSelectedItem(url.split('/')[1])
    if (isExternal) {
      window.open(url, '_blank')
    } else {
      navigateToUrl(url)
    }
  }

  return (
    <EnforceLoginProvider>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <LocationProvider>
            <>
              <IconButton
                data-testid="expand-collapse-button"
                data-guiding-id="nav-expand-collapse"
                onClick={() => setOpen(!open)}
                sx={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  left: currentWidth - 15,
                  top: '88px',
                  backgroundColor: '#FFFFFF',
                  margin: 0,
                  padding: 0,
                  position: 'fixed',
                }}
                className={classes.button}
              >
                <ChevronRight />
              </IconButton>

              <SearchLocation
                open={isLocationSelectorModalOpen}
                onClose={() => setIsLocationSelectorModalOpen(false)}
              />

              <Drawer
                variant="permanent"
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
                open={open}
                sx={{ width: currentWidth }}
                PaperProps={{
                  sx: {
                    width: currentWidth,
                  },
                }}
              >
                <div className={classes.drawerContent}>
                  <Toolbar className={classes.toolbar}>
                    {open && Object.keys(menuBanner).length > 0 ? (
                      <>
                        <menuBanner.expanded
                          data-testid="expanded-banner-logo"
                          style={{ width: '100%', fontSize: '60px' }}
                        />
                      </>
                    ) : Object.keys(menuBanner).length > 0 ? (
                      <>
                        <menuBanner.collapsed
                          data-testid="collapsed-banner-logo"
                          style={{ width: '100%', fontSize: '60px' }}
                        />
                      </>
                    ) : null}
                  </Toolbar>
                  <div style={{ width: '100%', padding: '0px 20px' }}>
                    <hr className={classes.divider}></hr>
                  </div>
                  {showLocationBar ? (
                    <div className={classes.locationWrapper}>
                      <LocationSelector
                        drawerOpen={open}
                        open={openDropdown}
                        setOpen={setIsLocationSelectorModalOpen}
                      />
                    </div>
                  ) : null}
                  <List className={classes.menuList}>
                    {menuItems.map((item, index) =>
                      item.subMenu ? (
                        <ParentMenuElement
                          key={index}
                          menuOpen={open}
                          handleClick={handleClick}
                          item={item}
                        />
                      ) : (
                        <ChildMenuElement
                          key={index}
                          menuOpen={open}
                          handleClick={handleClick}
                          item={item}
                        />
                      )
                    )}
                    <Divider variant="middle" />
                  </List>

                  {open && (
                    <div className={classes.navigatorFooter}>
                      <div className={classes.footerContent}>
                        {portalVersion?.version && (
                          <Typography
                            variant="caption"
                            display="block"
                            className={classes.footerItem}
                          >
                            v{portalVersion.version}
                          </Typography>
                        )}
                        <Link
                          href="https://fortispay.com/privacy-and-terms/"
                          target="_blank"
                          variant="caption"
                          className={classes.footerItem}
                          data-guiding-id="nav-privacypolicy"
                        >
                          {i18n.t('ftp-navigator.privacy-policy')}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </Drawer>
            </>{' '}
          </LocationProvider>
        </ErrorBoundary>
      </I18nextProvider>
    </EnforceLoginProvider>
  )
}
