import { ListItemButton, ListItemIcon, Typography } from '@mui/material'

import { NavigatorMenuItem } from '@shared/types'

interface ChildMenuElementProps {
  menuOpen: boolean
  handleClick: (url: string, isExternal?: boolean) => void
  item: NavigatorMenuItem
}

export const ChildMenuElement = ({
  menuOpen,
  handleClick,
  item,
}: ChildMenuElementProps) => {
  return (
    <ListItemButton
      component="button"
      data-guiding-id={item.guidingId}
      onClick={() => item.url && handleClick(item.url, item.isExternal)}
      style={{
        width: '100%',
        justifyContent: menuOpen ? 'flex-start' : 'center',
        padding: '12px 24px',
        marginBottom: '0.75rem',
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'white',
          boxShadow: '0px 6px 4px -2px rgba(0, 0, 0, 0.20)',
          border: '0.7px solid #D1D5DB',
        },
      }}
    >
      <ListItemIcon
        sx={{
          color: '#D64123',
          justifyContent: menuOpen ? 'flex-start' : 'center',
        }}
      >
        {item.icon}
      </ListItemIcon>
      {menuOpen && (
        <Typography variant="h6" component="div" fontSize="1rem">
          {item.label}
        </Typography>
      )}
    </ListItemButton>
  )
}
