import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { ChevronRight, MoreHoriz } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'

import { useLocations } from '@shared/hooks'

const useStyles = tss.withName('locationSelector').create(({ theme }) => ({
  dropdownSelected: {
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    borderRadius: '6px',
    padding: '8px 12px',
    zIndex: 2000,
    backgroundColor: '#F3F4F6',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: `${theme.palette['neutral-100']} !important`,
    },
    color: `${theme.palette['neutral-900']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
  },
  locationName: {
    whiteSpace: 'normal',
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette['neutral-500'],
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
  },
}))

interface LocationSelectorProps {
  drawerOpen: boolean
  open: boolean
  setOpen: (open: boolean) => void
}

const LocationSelector = ({
  drawerOpen,
  open,
  setOpen,
}: LocationSelectorProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { selectedLocation } = useLocations()

  const handleClick = (event) => {
    event.stopPropagation()
    setOpen(!open)
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          setOpen(!open)
        }
      }}
      className={classes.dropdownSelected}
      data-guiding-id="nav-location-selector"
    >
      {drawerOpen && (
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Box className={classes.container}>
              <Typography className={classes.label}>
                {t('common.location')}
              </Typography>
              <Typography className={classes.locationName}>
                {selectedLocation
                  ? selectedLocation.name
                  : t('common.select-a-location')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} display="flex">
            <ChevronRight />
          </Grid>
        </Grid>
      )}

      {!drawerOpen && <MoreHoriz />}
    </div>
  )
}

export default LocationSelector
