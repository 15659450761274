import { useState } from 'react'
import { tss } from 'tss-react/mui'

import { ArrowForwardIos } from '@mui/icons-material'
import { ListItemButton, ListItemText, Menu, MenuItem } from '@mui/material'

import appTheme from '@shared/design'
import { NavigatorSubMenuItem } from '@shared/types'

interface PopupMenuElementProps {
  handleClick: (url: string) => void
  item: NavigatorSubMenuItem
}

const useStyles = tss.withName('PopupMenuElement').create(({ theme }) => ({
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  menuItem: {
    padding: '12px 24px',
  },
}))

export const PopupMenuElement = ({
  handleClick,
  item,
}: PopupMenuElementProps) => {
  const { classes } = useStyles(appTheme)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  return (
    <>
      <ListItemButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{
          position: 'relative',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <div
          style={{
            border: '1px solid #e0e0e0',
            width: '0.75rem',
            top: '50%',
            left: 0,
            position: 'absolute',
          }}
        />

        <div className={classes.buttonContent}>
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              fontSize: '1rem',
            }}
          />
          <ArrowForwardIos
            sx={{
              fontSize: '1rem',
            }}
          />
        </div>
      </ListItemButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiList-root': {
            padding: 0,
          },
        }}
      >
        {item.subMenu?.map((subItem, index) => (
          <MenuItem
            key={index}
            className={classes.menuItem}
            onClick={() => {
              handleClick(subItem.url)
              setAnchorEl(null)
            }}
            data-guiding-id={subItem.guidingId}
          >
            {subItem.label}
          </MenuItem>
        ))}
      </Menu>{' '}
    </>
  )
}
