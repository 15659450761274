import { Fragment, useEffect, useMemo, useState } from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import { NavigatorMenuItem } from '@shared/types'

import { PopupMenuElement } from '../popup-menu-element/PopupMenuElement'

interface ParentMenuElementProps {
  menuOpen: boolean
  handleClick: (url: string, isExternal?: boolean) => void
  item: NavigatorMenuItem
}

export const ParentMenuElement = ({
  menuOpen,
  handleClick,
  item,
}: ParentMenuElementProps) => {
  const [floatingMenuOpen, setFloatingMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClickMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    setFloatingMenuOpen(!floatingMenuOpen)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
    setFloatingMenuOpen(false)
  }

  /*
   * This is a workaround to flatten the submenus
   * This is necessary to render the submenus in the floating menu
   */
  const allChildren = useMemo(() => {
    return item.subMenu?.reduce((acc, subItem) => {
      if (subItem.subMenu) {
        const subMenu = subItem.subMenu.map((subSubItem) => ({
          ...subSubItem,
          label: `${subItem.label} - ${subSubItem.label}`,
        }))
        return [...acc, ...subMenu]
      } else {
        return [...acc, subItem]
      }
    }, [] as NavigatorMenuItem[])
  }, [item])

  useEffect(() => {
    if (!menuOpen && floatingMenuOpen) handleCloseMenu()
  }, [menuOpen])

  return (
    <>
      <ListItemButton
        onClick={handleClickMenu}
        aria-controls={
          floatingMenuOpen && !menuOpen ? 'floating-menu' : undefined
        }
        data-guiding-id={`${item.guidingId}-toggle`}
        aria-haspopup="true"
        aria-expanded={floatingMenuOpen && !menuOpen ? 'true' : undefined}
        style={{
          width: '100%',
          justifyContent: menuOpen ? 'flex-start' : 'center',
          padding: '12px 24px',
          marginBottom: '0.75rem',
        }}
        id="floating-button"
        sx={{
          '&:hover': {
            backgroundColor: 'white',
            boxShadow: '0px 6px 4px -2px rgba(0, 0, 0, 0.20)',
            border: '0.7px solid #D1D5DB',
          },
        }}
      >
        <ListItemIcon
          sx={{
            color: '#D64123',
            justifyContent: menuOpen ? 'flex-start' : 'center',
          }}
        >
          {item.icon}
        </ListItemIcon>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {menuOpen && (
            <>
              <Typography variant="h6" component="div" fontSize="1rem">
                {item.label}
              </Typography>
              {floatingMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </div>
      </ListItemButton>

      {menuOpen && (
        <Collapse
          in={floatingMenuOpen}
          timeout="auto"
          unmountOnExit
          sx={{
            borderLeft: '1px solid #e0e0e0',
            ml: 4,
          }}
        >
          <List
            component="div"
            disablePadding
            sx={{
              position: 'relative',
            }}
          >
            {item.subMenu?.map((subItem, index) => (
              <Fragment key={index}>
                {subItem.subMenu?.length > 0 ? (
                  <PopupMenuElement handleClick={handleClick} item={subItem} />
                ) : (
                  <ListItemButton
                    data-guiding-id={
                      subItem.subMenu?.length > 0
                        ? `${subItem.guidingId}-toggle`
                        : subItem.guidingId
                    }
                    key={index}
                    onClick={() => {
                      handleClick(subItem.url, subItem.isExternal)
                    }}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        width: '0.75rem',
                        top: '50%',
                        left: 0,
                        position: 'absolute',
                      }}
                    />

                    <ListItemText
                      primary={subItem.label}
                      primaryTypographyProps={{
                        fontSize: '1rem',
                      }}
                    />
                  </ListItemButton>
                )}
              </Fragment>
            ))}
          </List>
        </Collapse>
      )}

      {!menuOpen && floatingMenuOpen && (
        <Menu
          open
          id="floating-menu"
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'floating-button',
          }}
          slotProps={{
            paper: {
              style: {
                maxHeight: '25rem',
              },
            },
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiList-root': {
              padding: 0,
            },
          }}
        >
          {allChildren?.map((subItem, index) => (
            <MenuItem
              key={index}
              style={{
                padding: '12px 24px',
              }}
              onClick={() => {
                handleClick(subItem.url, subItem.isExternal)
                handleCloseMenu()
              }}
            >
              {subItem.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}
