import { MerchantAccount } from '../../api/src'
import { AccountType } from '../../types'

export const personalSECCodes: SECCodes[] = [
  {
    label: 'PPD',
    value: 'PPD',
  },
  {
    label: 'TEL',
    value: 'TEL',
  },
  {
    label: 'WEB',
    value: 'WEB',
  },
]

export const businessSECCodes: SECCodes[] = [
  {
    label: 'CCD',
    value: 'CCD',
  },
]

type SECCodes = {
  label: string
  value: string
}

export const getSECCodes = (
  accountType: AccountType,
  merchantAccount?: MerchantAccount
) => {
  let allSECCodes = [...personalSECCodes, ...businessSECCodes]

  if (
    merchantAccount?.processor === 'fortisach' &&
    merchantAccount?.processor_data?.ach_sec_codes
  ) {
    allSECCodes = (
      merchantAccount?.processor_data?.ach_sec_codes as string[]
    ).map((item) => ({
      label: item,
      value: item,
    }))
  }

  if (accountType === 'personal') {
    const personalCodes = allSECCodes.filter(
      (item) => !businessSECCodes.some((code) => code.value === item.value)
    )
    return personalCodes
  } else {
    const businessCodes = allSECCodes.filter(
      (item) => !personalSECCodes.some((code) => code.value === item.value)
    )
    return businessCodes
  }
}
