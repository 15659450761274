import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

import { ThemeProvider } from '@mui/material'

import AppTheme from '@shared/design'
import { LocationProvider } from '@shared/hooks'

import FtpNavigator from './FtpNavigator'

const HubNavigator = () => {
  return (
    <ThemeProvider theme={AppTheme}>
      <FtpNavigator />
    </ThemeProvider>
  )
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: HubNavigator,
  // errorBoundary(err, info, props) {
  //   // Customize the root error boundary for your microfrontend here.
  //   return null
  // },
})

export const { bootstrap, mount, unmount } = lifecycles
